
import introduction from './Introduction'

export default     {
    title:'Paper System',
    list:[
        introduction,
        {title:'环境',content:null},
        {title:'材料属性',content:null},
        {title:'层次 & 阴影',content:null},
        {title:'最近更新',content:null},
    ]
}