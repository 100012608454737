<template>
    <div class="demo">
        <slot></slot>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>

.demo {
    border: 1px solid $light2;
    padding: 16px;
}

</style>