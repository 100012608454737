import {TestDemo} from '@/views/demo/_index'


const content = [`
## 介绍

印感设计是一个专注于 web 平台设计系统，为设计师和开发人员提供了创建引人入胜的产品体验所需的框架。以传统的打印效果作为隐喻，旨在建立一个友好，可靠的视觉体系架构。`,
TestDemo,
`## 设计目标

传统的平面设计的发展已有逾百年历史，在平面广告，书籍排版等领域有了成熟的设计模式。而网页设计同样也是在一个平面上进行设计，并且随着
近年来浏览器性能以及屏幕分辨率显示效果的提升，网页设计愈发精细化。目前网页设计中已经有能力实现一些平面设计上的设计效果。在传统打印的隐喻下对网页设计的规则进行探索，并提炼出一套科技、创新相结合而创造的设计语言，这是印感设计的设计目标。

## 约束 & 拓展

随着浏览器的发展，网页设计日新月异。浏览器的性能提升以及标准的愈发完善，让设计师以及开发人员有能力实现更加多样化的展示效果。于此同时，设计师和开发人员也有责任保持一套设计规则进行约束以保证繁杂的样式效果不应下影响用户的阅读体验。传统的平面设计为这种约束提供了一种思路。

但于此同时，必须意识到的是传统的平面设计与目前的网页设计之间存在着一种不可逾越的鸿沟 —— 静态/动态。所以在运用的这些规则时候需要对于其进行拓展以适应网页环境。
`]
export default {
    id: 'abtruct',
    title: '概述',
    content
}