<template>
  <div class="flex-fixed">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.flex-fixed {
  flex: none;
}
</style>
